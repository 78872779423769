import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from './Markdown';
import ReactPlayer from 'react-player';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/material';
// import './ReelCard.css'

interface ReelCardProps {
  content: string;
  title: string;
  videoRef: string;
}

export default function ReelCard(props: ReelCardProps) {
  const { content, title, videoRef } = props;

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Markdown className="markdown" key={content.substring(0, 40)}>
              {content}
            </Markdown>
          </AccordionDetails>
        </Accordion>
      </Typography>
      <Typography variant="h1" gutterBottom align="center">
        <ReactPlayer url={videoRef} controls
          width="1048px"
          height="546px"
        />
      </Typography>
    </Container>
  );
}
