import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MainFeaturedPost from '../../components/MainFeaturedPost';
import Footer from '../../components/Footer';
import ReelCard from '../../components/ReelCard';
import { Divider } from '@mui/material';
import { SpaceBar } from '@mui/icons-material';

interface MyAnimationsProps {
    posts: ReadonlyArray<string>;
}

const mainFeaturedPost = {
    title: 'Ardra Pradeep',
    description: "",
    image: '',
    imageText: 'My Image',
    linkText: 'Continue reading…',
};

const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function MyAnimations(props: MyAnimationsProps) {
    const { posts } = props;
    mainFeaturedPost.description = posts[0];
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Container maxWidth="lg">
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container alignItems="center">
                        <ReelCard content={posts[2]} title={'April 2024 Reel'} videoRef={'https://vimeo.com/936810339'} />
                        <ReelCard content={posts[1]} title={'Graduation Reel'} videoRef={'https://vimeo.com/858463138'} />
                        <ReelCard content={posts[3]} title={'March 11 second club entry'} videoRef={'https://vimeo.com/937481433'} />
                    </Grid>
                </main>
            </Container>
            <Footer
                title=""
                description=""
            />
        </ThemeProvider>
    );
}