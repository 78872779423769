import * as React from 'react';
import { useState, useEffect } from 'react'
import aboutMeContent from './assets/markdownFiles/AboutMe.md';
import gradReelContent from './assets/markdownFiles/GradReel.md';
import demoReelContent from './assets/markdownFiles/DemoReel.md';
import march11SecClubContent from './assets/markdownFiles/March11SecClub.md'
import './index.css';
import MyAnimations from './pages/animation/MyAnimations';

export default function App() {
  const [aboutMe, setAboutMeContent] = useState('')
  const [gradReel, setGradReelContent] = useState('')
  const [demoReel, setDemoReelContent] = useState('')
  const [march11SecClubReel, setMarch11SecClubContent] = useState('')
  const posts = [aboutMe,
    gradReel, demoReel, march11SecClubReel
  ];

  useEffect(() => {
    fetch(aboutMeContent).then(res => res.text()).then(text => setAboutMeContent(text))
  })
  useEffect(() => {
    fetch(gradReelContent).then(res => res.text()).then(text => setGradReelContent(text))
  })
  useEffect(() => {
    fetch(demoReelContent).then(res => res.text()).then(text => setDemoReelContent(text))
  })
  useEffect(() => {
    fetch(march11SecClubContent).then(res => res.text()).then(text => setMarch11SecClubContent(text))
  })
  return (
    <MyAnimations posts={posts} />
  );
}
