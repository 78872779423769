import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import AvatarImage from '../assets/images/avatar/ardraAvatar.jpg'
import AvatarBanner from '../assets/images/banner/profileBanner.png'
import Markdown from 'markdown-to-jsx';
import Stack from '@mui/material/Stack';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';

interface MainFeaturedPostProps {
  post: {
    description: string;
    image: string;
    imageText: string;
    linkText: string;
    title: string;
  };
}

export default function MainFeaturedPost(props: MainFeaturedPostProps) {
  const { post } = props;
  const social = [
    { name: 'LinkedIn', icon: LinkedInIcon, link: "https://www.linkedin.com/in/ardra-pradeep-8169b597/" },
    { name: 'ArtStation', icon: FormatPaintIcon, link: "https://ardrapradeep.artstation.com/" },
  ];

  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${AvatarBanner})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={AvatarBanner} alt={post.imageText} />}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.3)',
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography component="h1" variant="h1" color="inherit" gutterBottom>
              <Avatar
                alt="Ardra"
                src={AvatarImage}
                sx={{ width: 166, height: 166 }}
              />
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              <Markdown className="markdown" key={post.description.substring(0, 40)}>
                {post.description}
              </Markdown>
            </Typography>
            <Typography variant="h6" align="center" gutterBottom sx={{ mt: 3 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                {
                  social.map((network) => (

                    <Link
                      display="block"
                      variant="body1"
                      href={network.link}
                      key={network.name}
                      sx={{ mb: 0.5 }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <network.icon />
                        <span>{network.name}</span>
                      </Stack>
                    </Link>

                  ))
                }
              </Stack>
            </Typography >
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
